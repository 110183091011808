html {
  scroll-behavior: smooth !important;
  font-family: "Poppins", "Roboto", "Helvetica", "Arial", sans-serif !important;
}
::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: linear-gradient(
    to bottom,
    rgba(247, 65, 52, 0.5),
    rgba(235, 28, 97, 0.5)
  ) !important;
}

::-webkit-scrollbar-thumb:hover {
  border-radius: 10px;
  background: linear-gradient(to top, #f74134, #eb1c61) !important;
}
@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
